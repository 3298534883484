module.exports = {
  current: 'currentColor',
  black: '#151515',
  white: '#FFF',
  none: 'transparent',
  teal: '#43B4A6',
  'bluebook-black': '#505050',
  'bluebook-black1': '#000000',
  'bluebook-white1': '#F7F7F7',
  'bluebook-brown-highlight': '#F7F1EF',

  'teal-100': '#D7FBF6',
  'teal-200': '#8AE2D8',
  'teal-500': '#43B4A6',
  'teal-600': '#069B89',

  'gray-100': '#FAFAFA',
  'gray-200': '#F4F4F5',
  'gray-300': '#E4E4E7',
  'gray-400': '#D4D4D8',
  'gray-500': '#A1A1AA',
  'gray-600': '#71717A',
  'gray-700': '#52525B',
  'gray-800': '#2F2F32',
  'gray-900': '#1E1E1E',
  'bluebook-light-gray': '#F0F0F0',
  'bluebook-dark-gray': '#343434',
  'bluebook-gray1': '#505050',
  'bluebook-gray2': '#e2e2e2',
  'bluebook-gray3': '#cccccc',
  'bluebook-gray4': '#888888',
  'bluebook-gray5': '#b2b2b2',
  'bluebook-gray6': '#757575',
  'bluebook-gray7': '#484848',
  'bluebook-gray8': '#F5F5F5',
  'bluebook-gray9': '#E9E9E9',
  'bluebook-gray10': '#6B6B6B',
  'bluebook-gray-400': '#D4D4D8',
  'bluebook-gray-900': '#1E1E1E',

  'blue-100': '#EEF7FF',
  'blue-200': '#D4EDFA',
  'blue-300': '#B2E7FD',
  'blue-400': '#71c5e8',
  'blue-500': '#3D9CD6',
  'blue-600': '#0077c8',
  'blue-700': '#015CB0',
  'blue-800': '#034088',
  'blue-900': '#152664 ',
  'csa-lab-blue-light': '#C4CDFF',
  'csa-lab-blue-dark': '#142EB5',
  'csa-lab-blue-lighter': '#E9ECFF',

  'blue-light-100': '#EAFAFF',
  'blue-light-700': '#06A4D6',

  'bluebook-blue': '#324dc7',
  'bluebook-blue1': '#E6EDF8',
  'bluebook-blue2': '#DCE3EE',
  'bluebook-dark-blue': '#192764',
  'bluebook-blue-highlight': '#e7f5ff',
  'bluebook-blue-highlight-selected': '#b4dfff',

  'purple-100': '#FFEEFE',
  'purple-200': '#F4CBF5',
  'purple-300': '#E7A9EB',
  'purple-400': '#E28CEE',
  'purple-500': '#C564D3',
  'purple-600': '#A338BB',
  'purple-700': '#702f8a',
  'purple-800': '#702F8A',
  'purple-900': '#3F023B',
  'bluebook-purple-highlight': '#f3edfc',

  'green-100': '#E4FFD4',
  'green-200': '#BFF5AA',
  'green-300': '#95E376',
  'green-400': '#6CC24A',
  'green-500': '#4FA641',
  'green-600': '#217E26',
  'green-700': '#14622B',
  'green-800': '#074C28',
  'green-900': '#00331F',
  'bluebook-green1': '#3cbe26',
  'bluebook-green-highlight': '#f3f8eb',

  'yellow-100': '#FFF9DF',
  'yellow-200': '#FFEC9C',
  'yellow-300': '#FFE25D',
  'yellow-400': '#FEDB00',
  'yellow-500': '#D8B317',
  'yellow-600': '#AE8A01',
  'yellow-700': '#927200',
  'yellow-800': '#5E4800',
  'yellow-900': '#5E4800',
  'bluebook-yellow-highlight': '#fffad7',
  'bluebook-yellow-highlight-selected': '#ffe898',

  'orange-100': '#FFF4DA',
  'orange-200': '#FFDDA6',
  'orange-300': '#FDC77C',
  'orange-400': '#FBAE4B',
  'orange-500': '#F79D38',
  'orange-600': '#E17C19',
  'orange-700': '#974204',
  'orange-800': '#6A3300',
  'orange-900': '#402900',
  'bluebook-orange-highlight': '#fff0e8',

  'red-100': '#FFF1F1',
  'red-200': '#F9D1D2',
  'red-300': '#F4B0B5',
  'red-400': '#E27A83',
  'red-500': '#D0505F',
  'red-600': '#C13145',
  'red-700': '#922732',
  'red-800': '#68151A',
  'red-900': '#4B1012',
  'bluebook-red1': '#AB2334',
  'bluebook-red2': '#c13145',
  'bluebook-red-highlight': '#ffe5f8',
  'bluebook-red-highlight-selected': '#fecff2',

  'pink-100': '#FCE7F3',
  'pink-200': '#FBCFE8',
  'pink-300': '#F9A8D4',
  'pink-400': '#F472B6',
  'pink-500': '#EC4899',
  'pink-600': '#DB2777',
  'pink-700': '#BE185D',
  'pink-800': '#9D174D',
  'pink-900': '#831843',

  'bluebook-shared-passage-yellow-highlight': '#F0F0DB',
  'bluebook-shared-passage-yellow-blue-highlight': '#EAF0DB',
  'bluebook-shared-passage-yellow-red-highlight': '#F0E6DB',
  'bluebook-shared-passage-blue-highlight': '#EAF0F0',
  'bluebook-shared-passage-blue-red-highlight': '#EAE6F0',
  'bluebook-shared-passage-red-highlight': '#F0E6F0',
  'bluebook-shared-passage-yellow-blue-red-highlight': '#EAE6DB',
};
